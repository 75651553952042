/**
 * Funkcia na tvorbu nahodneho idcka
 * @param {String} type - typ komponentu - lubovolny nazov
 * @return - string s hashom
 */
function createRandomId (type) {
  function s4 () {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return `${type}-${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}

export default createRandomId
