<template>
  <div
    class="assign-modal-overlay"
    @click.stop="$emit('close-modal')"
  >
    <div
      class="modal"
      @click.stop
    >
      <div class="modal-header">
        <h5>
          {{ $t('dashboard.error-table.modal.heading') }}
        </h5>
      </div>
      <div class="modal-body">
        <custom-select-input
          v-model="assignedUser"
          :options="freeUsers"
          :default-label="$t('dashboard.error-table.modal.label')"
          class="select-input"
        />
        <ul class="data-list">
          <li class="data-item date-time">
            {{ dateHelper(modalData.createdAt) }} {{ timeHelper(modalData.createdAt) }}
          </li>
          <li class="data-item">
            {{ modalData.machine }}
          </li>
          <li
            v-if="modalData.status.length > 0"
            class="data-item"
          >
            <div
              v-if="modalData.status === 'STOPPED'"
              class="status"
            >
              <img
                :src="CardIcon1"
                :alt="$t('error-report.machines-report.stopped')"
                class="status-icon"
              >
              {{ $t('error-report.machines-report.stopped') }}
            </div>
            <div
              v-if="modalData.status === 'LIMITED'"
              class="status"
            >
              <img
                :src="CardIcon2"
                :alt="$t('error-report.machines-report.limited')"
                class="status-icon"
              >
              {{ $t('error-report.machines-report.limited') }}
            </div>
            <div
              v-if="modalData.status === 'POOR_QUALITY'"
              class="status"
            >
              <img
                :src="CardIcon3"
                :alt="$t('error-report.machines-report.poorQuality')"
                class="status-icon"
              >
              {{ $t('error-report.machines-report.poorQuality') }}
            </div>
            <div
              v-if="modalData.status === 'PLANNED_MAINTENANCE'"
              class="status"
            >
              <img
                :src="CardIcon4"
                :alt="$t('error-report.machines-report.plannedMaintenance')"
                class="status-icon"
              >
              {{ $t('error-report.machines-report.plannedMaintenance') }}
            </div>
            <div
              v-if="modalData.status === 'RUNNING'"
              class="status"
            >
              <img
                :src="CardIcon5"
                :alt="$t('error-report.machines-report.running')"
                class="status-icon"
              >
              {{ $t('error-report.machines-report.running') }}
            </div>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <span
          @click="$emit('close-modal')"
        >
          {{ $t('dashboard.error-table.modal.cancel') }}
        </span>
        <custom-button
          :fullwidth="windowWidth <= 960 ? true : false"
          @click="assignToTask"
        >
          {{ $t('dashboard.error-table.modal.submit') }}
        </custom-button>
      </div>
    </div>
  </div>
</template>

<script>
import { timeHelper, dateHelper } from '@/helpers/dateHelpers'
import CardIcon1 from '@/assets/icons/stop_stav.svg'
import CardIcon2 from '@/assets/icons/obmedzena_funkcnost.svg'
import CardIcon3 from '@/assets/icons/nekvalita.svg'
import CardIcon4 from '@/assets/icons/planovana_oprava.svg'
import CardIcon5 from '@/assets/icons/oprava.svg'
import CustomSelectInput from '@/components/GlobalComponents/CustomSelectInput.vue'
import CustomButton from '@/components/GlobalComponents/CustomButton.vue'

export default {
  name: 'AssignEmployeeModal',
  components: {
    CustomSelectInput,
    CustomButton
  },
  props: {
    modalData: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      CardIcon1,
      CardIcon2,
      CardIcon3,
      CardIcon4,
      CardIcon5,
      freeUsers: null,
      assignedUser: null,
      windowWidth: 0
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  created () {
    this.getUsers()
  },
  methods: {
    dateHelper,
    timeHelper,
    handleResize () {
      this.windowWidth = window.innerWidth
    },
    async getUsers () {
      try {
        // const { data } = await this.$axios.get('/users/user-statistics')
        const { data } = await this.$axios.get('/users')
        this.freeUsers = data.filter(x => x.role === 'MAINTAINER' && x.canClaimMachineError).map(u => ({
          value: u.id,
          label: `${u.firstName} ${u.lastName} <${u.email}>`
        }))
        console.log(data)
        console.log(this.freeUsers)
        console.log(this.modalData)
      } catch (err) {
        console.error(err)
      }
    },
    async assignToTask () {
      const sendData = {
        userId: this.assignedUser,
        machineErrorId: this.modalData.id
      }
      await this.$axios.post('/machine-errors/attach-user-to-error', sendData)
      await this.getUsers()
      this.$emit('close-modal')
      this.$emit('rerender')
    }
  }
}
</script>

<style lang="scss" scoped>
div.assign-modal-overlay {
  z-index: 99998;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);

  div.modal {
    z-index: 99999;
    width: 100%;
    max-width: 442px;
    height: 100%;
    max-height: 468px;
    background-color: $primary-white;
    padding: 40px 32px 32px 32px;

    div.modal-header {
      margin-bottom: 24px;

      h5 {
        font-weight: 500;
      }
    }

    div.modal-body {
      .select-input {
        margin-bottom: 24px;
      }

      ul.data-list {
        margin: 0;
        padding: 0;
        background-color: #F8F8F8;
        list-style-type: none;
        display: flex;
        flex-direction: column;

        li.data-item {
          display: flex;
          align-items: center;
          padding: 20px 24px;
          border-bottom: 1px solid $primary-lightgrey;
          font-size: 14px;
          letter-spacing: 0.25px;

          &:last-child {
            border-bottom: none;
          }

          &.date-time {
            font-weight: 500;
          }

          div.status {
            display: flex;
            align-items: center;
            font-weight: 500;

            img.status-icon {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
          }
        }
      }
    }

    div.modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 24px;

      span {
        margin-right: 40px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.assign-modal-overlay {
    div.modal {
      margin: 0 24px;
      padding: 24px;

      div.modal-header {
        text-align: center;
      }

      div.modal-body {
        ul.data-list {
          li.data-item {
            padding: 16px;
            line-height: 21px;
            letter-spacing: 0.5px;
          }
        }
      }

      div.modal-footer {
        flex-direction: column-reverse;
        width: 100%;

        span {
          margin: 24.5px 0 0 0;
        }
      }
    }
  }
}
</style>
