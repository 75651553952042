<template>
  <div
    :id="defaultId"
    v-click-outside="close"
    class="select-input-form"
  >
    <span
      v-if="outsideLabel"
      class="outside-label"
      :class="{'disabled': disabled}"
      @click="toggleOpen"
    >
      <!-- @slot Use this slot to create label -->
      <slot name="outside-label" />
    </span>
    <div class="select-container">
      <div
        class="select"
        :title="title"
        :class="{ disabled: disabled, 'opened-dropdown': selectOpen}"
        @click="toggleOpen"
      >
        <div class="label">
          {{ selectedLabel }}
        </div>
        <img
          :src="ArrowDownSelect"
          alt="Icon"
          :class="{opened: selectOpen}"
        >
      </div>
      <transition name="fade">
        <ul
          v-if="selectOpen"
          class="options"
        >
          <li
            v-for="(option, index) in options"
            :key="index"
            :class="{'selected-option': option[optionLabel] === selectedLabel}"
            @click="onChange(option)"
          >
            {{ option[optionLabel] }}
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
import createRandomId from '@/helpers/createRandomId'
import ArrowDownSelect from '@/assets/icons/arrow_down.svg'

export default {
  name: 'CustomSelectInput',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    defaultLabel: {
      type: String,
      default: 'Select'
    },
    /**
     * Which option property is used for label
     */
    optionLabel: {
      type: String,
      default: 'label'
    },
    /**
     * Which option property is used for value
     */
    optionValue: {
      type: String,
      default: 'value'
    },
    id: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Open or close select dropdown
     */
    open: {
      type: Boolean,
      default: false
    },
    /**
     * You can pass a descriptive title which
     * will work instead of placeholder if needed.
     * On a long hover it will create a popup
     */
    title: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Show or hide outside label. Use named @slot to create label
     */
    outsideLabel: {
      type: Boolean,
      default: false
    },
    /**
     * Value of the options
     * It is an Array of Objects
     * Needed keys label and value
     * Example: {label:'One', value: 10}
     */
    options: {
      type: Array,
      default: () => ([])
    },
    /**
     * Value of the input
     */
    value: {
      type: [String, Object, Number, Boolean],
      default: undefined
    },
    /**
     * Disabled state
     */
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultId: this.id || createRandomId('SelectInput'),
      selectOpen: this.open,
      ArrowDownSelect
    }
  },
  computed: {
    isSelected () {
      return this.value !== null && this.value !== undefined && this.value !== ''
    },
    selectedLabel () {
      let selectedLabel = this.defaultLabel
      if (
        this.value !== undefined &&
        this.value !== null &&
        this.options && this.options.length > 0
      ) {
        const option = this.options.find(o => o[this.optionValue] === this.value)
        if (option) {
          selectedLabel = option[this.optionLabel]
        }
      }
      return selectedLabel
    }
  },
  methods: {
    onChange (item) {
      this.selectOpen = false
      this.$emit('change', item[this.optionValue])
    },
    close () {
      if (!this.disabled) {
        this.selectOpen = false
      }
    },
    toggleOpen () {
      if (!this.disabled) {
        this.selectOpen = !this.selectOpen
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div.select-input-form {
  z-index: inherit;
  display: flex;
  flex-direction: column;
  width: 100%;

  span.outside-label {
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    font-weight: bold;
    color: $primary-black;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }
  }

  div.select-container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 56px;

    div.select {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding: 14px 12px;
      letter-spacing: 0.5px;
      cursor: pointer;
      background-color: $primary-white;
      border: 1px solid rgba(0, 0, 0, 0.42);
      transition: all 0.2s;

      &.disabled {
        cursor: not-allowed;
      }

      &.opened-dropdown {
        border-bottom: 0;
        transition: all 0.2s;
      }

      div.label {
        max-width: 100%;
        color: rgba(0, 0, 0, 0.87);
      }

      img {
        width: 24px;
        height: 24px;
        transition: transform 300ms ease-in-out;

        &.opened {
          transform: rotateX(180deg);
        }
      }
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: all 0.1s;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }

    ul.options {
      position: absolute;
      z-index: 100;
      top: 56px;
      width: 100%;
      height: 300px;
      overflow-y: scroll;
      list-style: none;
      background: linear-gradient(180deg, rgba(247, 247, 247, 1) 0%, rgba(250, 250, 250, 1) 15%, rgba(255, 255, 255, 1) 100%);
      box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.08);
      border: 1px solid rgba(0, 0, 0, 0.42);
      border-top: none;

      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 14px 12px;
        color: $primary-black;
        cursor: pointer;

        &.selected-option {
          position: relative;
          font-weight: 700;

          // &::before {
          //   position: absolute;
          //   left: 0.6875rem;
          //   content: '';
          //   border-radius: 50%;
          //   background-color: $primary-black;
          //   width: 3px;
          //   height: 3px;
          // }
        }
      }
    }
  }
}
</style>
