<template>
  <div class="current-machine-errors-table">
    <current-machine-errors-table-component
      :table-data="filteredErrors"
      class="errors-table"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CurrentMachineErrorsTableComponent from './CurrentMachineErrorsTableComponent'
import downloadCSV from '@/helpers/downloadCSV'
import { orderBy } from 'lodash'

export default {
  name: 'CurrentMachineErrorsTable',
  components: {
    CurrentMachineErrorsTableComponent
  },
  data () {
    return {
      errors: [],
      filteredErrors: [],
      interval: null
    }
  },
  computed: {
    ...mapGetters('User', ['getUserData'])
  },
  created () {
    this.getData()
    this.interval = setInterval(async () => {
      const old = new Set(this.errors?.map(e => e.id) ?? [])
      await this.getData()
      const newIds = this.errors?.map(e => e.id) ?? []
      if (!newIds.every(id => old.has(id))) {
        // const audio = new Audio(Fanfare)
        // audio.play()
      }
    }, ['ADMIN', 'HEAD_MAINTAINER'].includes(this.getUserData.role) ? 60000 : 120000)
  },
  beforeUnmount () {
    clearInterval(this.interval)
    this.interval = null
  },
  methods: {
    async getData () {
      try {
        // const statusMap = {
        //   PLANNED_MAINTENANCE: 'Plánovaná oprava',
        //   RUNNING: 'Funkčný stav, ale potreba servisu',
        //   POOR_QUALITY: 'Nekvalita',
        //   LIMITED: 'Obmedzená funkčnosť',
        //   STOPPED: 'STOP STAV'
        // }
        const strByType = {
          BUILDING_EQUIPMENT: 'Vybavnie budovy',
          WORKSHOP: 'Práca na dielni'
        }
        const userId = this.getUserData.id
        const response = await this.$axios.get('/machine-errors/?select=*,mainTask(user(*)),tasks(user(*)),machine(*),errorCategory(name),author(*),neededMaintainers,canBeClaimed&filter.finishedAt.isnull=true')
        console.log(response)
        console.log(orderBy(
          [{ a: false }, { a: true }],
          [
            'a'
          ],
          ['asc']
        ))
        this.errors = orderBy(
          response.data,
          [
            e => e.mainTask?.userId !== userId,
            'canBeClaimed',
            e => e.machine.priority
          ],
          ['asc', 'desc', 'asc']
        ).map(task => {
          const shouldClean = ['BUILDING_EQUIPMENT', 'WORKSHOP'].includes(task.machine.type)
          let errorStatus
          if (task.mainTask == null) {
            errorStatus = this.$t('dashboard.error-table.table.unassigned')
          } else {
            errorStatus = task.mainTask.user.fullName
            if (task.canBeClaimed) {
              errorStatus = this.$t('dashboard.error-table.table.withHelp') + task.mainTask.user.fullName
            }
            if (task.mainTask.activePause) {
              errorStatus = this.$t('dashboard.error-table.table.paused')
            }
          }
          return {
            id: task.id,
            category: this.$t('dashboard.error-table.table.categories.machines'),
            createdAt: task.createdAt,
            finishedAt: task.finishedAt,
            name: shouldClean ? task.machine.name : task.errorCategory.name,
            machine: strByType[task.machine.type] ?? task.machine.name,
            hall: task.machine.hall.name,
            // status: shouldClean
            //   ? ''
            //   : statusMap[task.machineStatus] ?? task.machineStatus,
            status: shouldClean
              ? ''
              : task.machineStatus,
            claim: task.canBeClaimed ? this.$t('dashboard.error-table.table.canBeClaimed') : task.author.fullName,
            description: task.description,
            errorStatus,
            maintainers: !!(task.neededMaintainers > 1 && task.canBeClaimed),
            highlight: !task.canBeClaimed && task.tasks?.some?.(task => task.userId === userId)
          }
        })
        this.getFilteredData()
      } catch (err) {
        console.error(err)
      }
    },
    getFilteredData () {
      const filteredData = this.errors.filter(item => item.finishedAt == null)
      console.log(filteredData)
      this.filteredErrors = filteredData
    },
    downloadFile () {
      downloadCSV(this.errors, 'data.csv')
    }
  }
}
</script>

<style lang="scss" scoped>
div.current-machine-errors-table {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 167px 167px 167px;

  .errors-table {
    margin-top: -48px;
    width: 100%;
  }
}

@media screen and (max-width: $mobile) {
  div.current-machine-errors-table {
    padding: 0;

    .errors-table {
      margin-top: 0;
    }
  }
}
</style>
