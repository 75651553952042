<template>
  <button
    class="custom-button"
    :disabled="disabled"
    :class="[type, size, {fullwidth, outlined}]"
    :style="styles"
    :type="type"
    @click="onClick($event)"
    @subit="onSubmit($event)"
    @blur="onBlur($event)"
    @focus="onFocus($event)"
    @keypress="onKeyPress($event)"
    @keydown="onKeyDown($event)"
    @keyup="onKeyUp($event)"
  >
    <span
      v-if="!!$slots.leftIcon"
      class="left-icon"
    >
      <!-- @slot Use this slot to place the left icon content -->
      <slot name="leftIcon" />
    </span>
    <!-- @slot Use this slot to place the button content -->
    <div>
      <slot> No content provided </slot>
    </div>
    <span
      v-if="!!$slots.rightIcon"
      class="right-icon"
    >
      <!-- @slot Use this slot to place the Right icon content -->
      <slot name="rightIcon" />
    </span>
  </button>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    fullwidth: {
      type: Boolean,
      default: false
    },
    /**
     * Sets the styles. To make the native style binding work.
     */
    styles: {
      type: Object,
      required: false,
      default: () => {}
    },
    /**
     * Sets disabled state on and off.
     */
    disabled: {
      default: false,
      type: Boolean,
      required: false
    },
    /**
     * Sets the type of component.
     * Allowed are: ['primary-custom-button', 'normal-custom-button', 'red-custom-button', 'white-custom-button']
     */
    type: {
      default: 'primary-custom-button',
      type: String,
      required: false,
      validator (prop) {
        return (
          [
            'primary-custom-button',
            'normal-custom-button',
            'red-custom-button',
            'white-custom-button'
          ].includes(prop) > -1
        )
      }
    },
    /*
    * Button outline
    */
    outlined: {
      default: false,
      type: Boolean,
      required: false
    },
    /**
     * Button height
     */
    size: {
      default: 'size-52',
      type: String,
      required: false,
      validator (prop) {
        return ['size-36', 'size-20', 'size-28', 'size-44', 'size-52'].includes(prop) > -1
      }
    }
  },
  methods: {
    onClick ($event) {
      /**
       * Triggered when button is clicked.
       * Allows to use component without .native
       *
       * @event click
       * @property {event}
       * @type {object}
       */
      this.$emit('click', $event)
    },
    onSubmit ($event) {
      /**
       * Triggered when button is submitted.
       * Allows to use component without .native
       *
       * @event click
       * @property {event}
       * @type {object}
       */
      this.$emit('submit', $event)
    },
    onBlur ($event) {
      /**
       * Triggered on button blur state.
       *
       * @event blur
       * @property {event}
       * @type {object}
       */
      this.$emit('blur', $event)
    },
    onFocus ($event) {
      /**
       * Triggered on button Focus state.
       *
       * @event focus
       * @property {event}
       * @type {object}
       */
      this.$emit('focus', $event)
    },
    onKeyPress ($event) {
      /**
       * Triggered on button Keypress state.
       *
       * @event focus
       * @property {event}
       * @type {object}
       */
      this.$emit('keypress', $event)
    },
    onKeyDown ($event) {
      /**
       * Triggered on button KeyDown state.
       *
       * @event focus
       * @property {event}
       * @type {object}
       */
      this.$emit('keydown', $event)
    },
    onKeyUp ($event) {
      /**
       * Triggered on button KeyUp state.
       *
       * @event focus
       * @property {event}
       * @type {object}
       */
      this.$emit('keyup', $event)
    }
  }
}
</script>

<style lang="scss" scoped>
button.custom-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: none;
  outline: none;
  transition: all 0.2s;

  &.fullwidth {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }

  &::first-letter {
    text-transform: capitalize;
  }

  &[disabled] {
    cursor: not-allowed;

    &:active {
      pointer-events: none;
    }
  }

  &.size-20 {
    height: 20px;
    padding: 0 16px;
  }

  &.size-28 {
    height: 28px;
    padding: 0 16px;
  }

  &.size-36 {
    height: 36px;
    padding: 0 16px;
  }

  &.size-44 {
    height: 44px;
    padding: 0 16px;
  }

  &.size-52 {
    height: 52px;
    padding: 0 16px;
  }

  span.left-icon {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 16px;
  }

  span.right-icon {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 16px;
  }

  &.primary-custom-button {
    color: $primary-white;
    font-weight: 500;
    background-color: $primary-lightorange;

    // &:hover {
    //   background-color: lighten($color: #000, $amount: 20%);
    // }

    // &:focus {
    //   background-color: lighten($color: #000, $amount: 30%);
    // }

    // &:active {
    //   background-color: lighten($color: #000, $amount: 40%);
    // }

    &[disabled] {
      color: $primary-grey;
      background-color: $primary-lightgrey;

      &.outlined {
        border: 1px solid $primary-black;
      }
    }

    &.outlined {
      border: 1px solid $primary-lightgrey;
    }
  }

  &.normal-custom-button {
    color: $primary-black;
    font-weight: 400;
    background-color: #F5F5F5;

    &[disabled] {
      color: $primary-grey;
      background-color: $primary-lightgrey;

      &.outlined {
        border: 1px solid $primary-grey;
      }
    }

    &.outlined {
      border: 1px solid $primary-black;
    }
  }

  &.red-custom-button {
    color: $primary-white;
    font-weight: 500;
    background-color: $status-red;

    &[disabled] {
      color: $primary-grey;
      background-color: $primary-lightgrey;

      &.outlined {
        border: 1px solid $primary-grey;
      }
    }
  }

  &.white-custom-button {
    color: $primary-black;
    font-weight: 500;
    background-color: $primary-white;
    border: 1px solid $primary-lightgrey;

    &[disabled] {
      color: $primary-grey;
      background-color: $primary-lightgrey;

      &.outlined {
        border: 1px solid $primary-grey;
      }
    }
  }
}
</style>
