import downloadFile from './downloadFile'
const converter = require('json-2-csv')

export default (data, fileName) => {
  converter.json2csv(data, (err, csv) => {
    if (err) {
      console.error(err)
      return
    }
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    downloadFile(blob, fileName)
  })
}
