export function dateHelper (dateString) {
  const date = new Date(dateString)
  if (Number.isNaN(date.getDate())) {
    return ''
  }
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

const padTime = num => `${num}`.padStart(2, '0')

export function timeHelper (dateString) {
  const date = new Date(dateString)
  if (Number.isNaN(date.getDate())) {
    return ''
  }
  return `${padTime(date.getHours())}:${padTime(date.getMinutes())}`
}
